/**@jsx jsx */
import {
  jsx,
  Grid,
  Box,
  Button,
  Flex,
  NavLink,
  MenuButton,
  Close,
  Image,
} from 'theme-ui';

import { useState } from 'react';

import logo_primary from '../images/resilient_coders_logo_primary.png';

import PropTypes from 'prop-types';

const Header = ({ handleBurgerClick, isShowingSideMenu }) => {
  const [positionTop, setPositionTop] = useState(0);

  const windowGlobal = typeof window !== 'undefined' && window;

  if (windowGlobal) {
    let prevScrollPos = windowGlobal.pageYOffset;
    windowGlobal.onscroll = () => {
      let currentScrollPos = windowGlobal.pageYOffset;

      prevScrollPos > currentScrollPos && setPositionTop(0);
      prevScrollPos < currentScrollPos && setPositionTop(-60);

      prevScrollPos = currentScrollPos;
    };
  }

  return (
    <header
      sx={{
        position: 'fixed',
        top: positionTop,
        transition: 'top .5s',
        width: '100%',
        zIndex: '99',
        left: 0,
        right: 0,
      }}
    >
      <Flex
        as={'nav'}
        sx={{
          margin: `0 auto`,
          backgroundColor: 'white',
          justifyContent: ['space-between', 'space-between', 'center'],
          maxWidth: '1150px',
        }}
      >
        <a
          href={'https://www.resilientcoders.org/'}
          sx={{
            variant: 'links.nav',
            backgroundColor: 'primary',
            width: '100%',
            borderLeft: 'none',
            maxWidth: '70px',
            '&:hover': {
              backgroundColor: 'primary',
              cursor: 'pointer',
            },
          }}
        >
          <Image
            alt={'Resilient Coders Logo'}
            sx={{ height: '100%' }}
            src={logo_primary}
          />
        </a>
        <a
          sx={{ borderLeft: 'none', variant: 'links.nav' }}
          href={'https://www.resilientcoders.org/'}
        >
          HOME
        </a>
        <a
          sx={{ variant: 'links.nav' }}
          href={'https://www.resilientcoders.org/bootcamp'}
        >
          BOOTCAMP
        </a>
        <a
          sx={{ variant: 'links.nav' }}
          href={'https://www.resilientcoders.org/hire'}
        >
          HIRE
        </a>
        <a
          sx={{ variant: 'links.nav' }}
          href={'https://www.resilientcoders.org/news'}
        >
          NEWS
        </a>
        <a
          sx={{ borderRight: 'none', variant: 'links.nav' }}
          href={'https://www.resilientcoders.org/team'}
        >
          TEAM
        </a>
        <a
          sx={{
            backgroundColor: 'primary',
            color: 'white',
            borderRight: 'none',
            variant: 'links.nav',
          }}
          href={'https://www.resilientcoders.org/donate'}
        >
          DONATE
        </a>

        <a
          sx={{
            variant: 'links.nav',
            display: ['block', 'block', 'none'],
            backgroundColor: 'primary',
            width: '68px',
            borderRight: 'none',
            '&:hover': {
              backgroundColor: 'primary',
              cursor: 'pointer',
            },
          }}
        >
          <Image sx={{ height: '100%' }} src={logo_primary} />
        </a>

        {isShowingSideMenu ? (
          <Close
            onClick={() => handleBurgerClick()}
            sx={{
              display: ['block', 'block', 'none'],
              transition: 'all 0.75s',
            }}
          />
        ) : (
          <MenuButton
            onClick={() => handleBurgerClick()}
            sx={{
              display: ['block', 'block', 'none'],
              transition: 'all 0.75s',
            }}
            aria-label="Toggle Menu"
          />
        )}
      </Flex>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
