/**@jsx jsx */
import { jsx, Container } from 'theme-ui';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './header';
import SideMenu from './side-menu';
import Footer from './footer';
import './layout.css';
import '../fonts/fonts.css';
require('typeface-nanum-gothic');
require('typeface-roboto-slab');

const Layout = ({ children }) => {
  const [isShowingSideMenu, setIsShowingMenu] = useState(false);

  const handleBurgerClick = () => {
    setIsShowingMenu(!isShowingSideMenu);
  };

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Container
      sx={{
        minWidth: '95vw',
        minHeight: '100vh',
        position: 'relative',
        overflow: 'hidden',
        overscrollBehavior: 'none',
        paddingTop: '90px',
      }}
    >
      <Header
        isShowingSideMenu={isShowingSideMenu}
        handleBurgerClick={handleBurgerClick}
        siteTitle={data.site.siteMetadata.title}
      />
      <SideMenu
        handleBurgerClick={handleBurgerClick}
        isShowingSideMenu={isShowingSideMenu}
      />
      <main>{children}</main>
      <Footer />
    </Container>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
