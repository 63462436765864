/**@jsx jsx */
import { jsx, Box, Container, Grid, Flex, Heading } from 'theme-ui';
import { Link } from 'gatsby';
import { Twitter, Facebook, Linkedin } from 'react-feather';

const Footer = () => {
  return (
    <Container
      sx={{ backgroundColor: 'background2', marginTop: '30px', color: 'text4' }}
      as={'footer'}
    >
      <Flex
        sx={{
          flexDirection: ['column', 'column', 'row'],
          justifyContent: 'space-between',
          padding: '20px',
        }}
      >
        <Grid sx={{ marginBottom: '20px' }} columns={[1, 1, 3]}>
          <Box>
            <Heading sx={{ variant: 'links.footer.heading' }} as={'h4'}>
              BOOTCAMP
            </Heading>
            <a
              sx={{ variant: 'links.footer' }}
              href={'https://resilientcoders.org/bootcamp'}
            >
              About
            </a>
            <Link sx={{ variant: 'links.footer' }} href={'/'}>
              Roster
            </Link>
          </Box>
          <Box>
            <Heading sx={{ variant: 'links.footer.heading' }} as={'h4'}>
              HIRE
            </Heading>
            <a
              sx={{ variant: 'links.footer' }}
              href={'https://resilientcoders.org/hire'}
            >
              About
            </a>
          </Box>
          <Box>
            <Heading sx={{ variant: 'links.footer.heading' }} as={'h4'}>
              GET INVOVLED
            </Heading>
            <a
              sx={{ variant: 'links.footer' }}
              href={'https://resilientcoders.org/get-involved/student'}
            >
              Students
            </a>
            <a
              sx={{ variant: 'links.footer' }}
              href={'https://resilientcoders.org/get-involved/volunteer'}
            >
              Volunteers
            </a>
            <a
              sx={{ variant: 'links.footer' }}
              href={'https://resilientcoders.org/get-involved/company'}
            >
              Companies
            </a>
          </Box>
        </Grid>
        <Box sx={{ color: 'text4' }}>
          <p>1 Broadway, 11th Floor. Cambridge, MA 02142</p>
          <p>Questions? Email: info@resilientcoders.org</p>
        </Box>
      </Flex>
      <Grid
        columns={[1, 1, 3]}
        sx={{
          borderTop: '1px solid',
          borderTopColor: 'text4',
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        <Flex
          sx={{
            fontSize: '12px',
            padding: '20px',
          }}
        >
          <Box>
            <a
              aria-label={'Click here to go to our Twitter'}
              sx={{ color: 'text4' }}
              href={'https://twitter.com/resilientcoders'}
              target={'__blank'}
            >
              <Twitter />
            </a>
            <a
              aria-label={'Click here to go to our Facebook'}
              sx={{ color: 'text4' }}
              href={'https://www.facebook.com/resilientcoders/'}
              target={'__blank'}
            >
              <Facebook />
            </a>
            <a
              aria-label={'Click here to go to our LinkedIn'}
              sx={{ color: 'text4' }}
              href={'https://www.linkedin.com/company/resilient-coders/'}
              target={'__blank'}
            >
              <Linkedin />
            </a>
          </Box>
        </Flex>
        <Box sx={{ padding: '20px' }}>
          <p>© 2023 Resilient Coders</p>
        </Box>
        <Box sx={{ padding: '20px' }}>
          <p>Resilient Coders</p>
          <p>1 Broadway, Floor 11 Cambridge, MA, 02142</p>
        </Box>
      </Grid>
    </Container>
  );
};

export default Footer;
