/**@jsx jsx */
import { jsx, Flex, Close } from 'theme-ui';
import { Link } from 'gatsby';

const SideMenu = ({ isShowingSideMenu, handleBurgerClick }) => {
  return (
    <Flex
      sx={{
        backgroundColor: 'secondary',
        color: 'white',
        position: 'fixed',
        right: isShowingSideMenu ? 0 : '-250px',
        transition: 'all 0.75s',
        flexDirection: 'column',
        justifyContent: 'space-around',
        zIndex: 20,
        padding: 20,
        width: '250px',
      }}
    >
      <Flex sx={{ justifyContent: 'flex-end' }}>
        <Close
          onClick={() => handleBurgerClick()}
          sx={{
            display: ['block', 'block', 'none'],
            transition: 'all 0.75s',
          }}
        />
      </Flex>
      <a
        to={'https://www.resilientcoders.org/'}
        sx={{ variant: 'links.sideMenu' }}
        href={'/'}
      >
        HOME
      </a>
      <a
        sx={{ variant: 'links.sideMenu' }}
        href={'https://www.resilientcoders.org/bootcamp'}
      >
        BOOTCAMP
      </a>
      <a
        sx={{ variant: 'links.sideMenu' }}
        href={'https://www.resilientcoders.org/hire'}
      >
        HIRE
      </a>
      <a
        sx={{ variant: 'links.sideMenu' }}
        href={'https://www.resilientcoders.org/news'}
      >
        NEWS
      </a>
      <a
        sx={{ variant: 'links.sideMenu' }}
        href={'https://www.resilientcoders.org/team'}
      >
        TEAM
      </a>
      <a
        sx={{ color: 'primary', variant: 'links.sideMenu' }}
        href={'https://www.resilientcoders.org/donate'}
      >
        DONATE
      </a>
    </Flex>
  );
};

export default SideMenu;
